import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
  }

  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 600px) {
    body {
      font-size: 14px;
    }
    button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
.user-auth-title {
     
    font-size: 28px;
    font-weight: 700;
    line-height: 33.89px;
    }
.check-policies {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin: 12px 0 0 0;
}
    .menu-item-title {
     
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: left;
}
 .menu_item-ml {
 
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
} 

.menu-item-price{
 
font-size: 20px;
font-weight: 400;
line-height: 24.2px;
text-align: center;
}
`;

export default GlobalStyle;
