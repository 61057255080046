import { Navigate } from "react-router-dom";

// Utility function to check if the token is expired
const isTokenExpired = (token?: string) => {
  if (!token) return true; // No token means it's "expired"

  try {
    const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    if (!exp) return true; // No expiration field means "expired"

    return Date.now() >= exp * 1000; // Check if current time is beyond the token's expiration time
  } catch (error) {
    return true; // Any error in processing token means it's treated as "expired"
  }
};

// The PrivateRoute component
const PrivateRoute = ({ children, redirectPath = "/" }: any) => {
  // Determine the token to check; prefer access token over refresh token
  const token: any = localStorage.getItem("accessToken") || null;

  // Check if the token is expired
  const isExpired = isTokenExpired(token);
  const locationId = localStorage.getItem("locationId");

  // If the token is valid (not expired), render the children components (protected content)
  if (!isExpired) {
    return <>{children}</>;
  }

  // If the token is expired or not present, redirect to the specified path
  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
