import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

// Core components
import GlobalStyle from "./GlobalStyle";
import PrivateRoute from "./services/routeGuadrd";

// Lazy loaded components
const ScanAgain = React.lazy(() => import("./pages/ScanAgain"));
const Locations = React.lazy(() => import("./pages/Locations"));
const Menu = React.lazy(() => import("./pages/Menu"));
const MenuItem = React.lazy(() => import("./pages/menu-items"));
const CartPage = React.lazy(() => import("./pages/Cart"));
const Login = React.lazy(() => import("./pages/Login"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Payment = React.lazy(() => import("./pages/Payment"));
const VerifyOTP = React.lazy(() => import("./pages/verifyOtp"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const OrderSuccess = React.lazy(() => import("./pages/OrderSuccess"));
const Orders = React.lazy(() => import("./pages/Orders"));

// Browser message component
const BrowserMessage: React.FC = () => (
  <div style={{ textAlign: "center", padding: "50px" }}>
    <h1>Mobile Only</h1>
    <p>
      This site is optimized for mobile devices. Please access it on your mobile
      phone.
    </p>
  </div>
);

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <BrowserView>
        <BrowserMessage />
      </BrowserView>

      <MobileView>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Public routes */}
              <Route path="" element={<ScanAgain />} />
              <Route path="/" element={<ScanAgain />} />
              <Route path="/:locationId?" element={<Locations />} />
              <Route path="/locations/:locationId" element={<Locations />} />
              <Route path="/menu/:locationId" element={<Menu />} />
              <Route
                path="/menu/:locationId/menu-items/:menuId"
                element={<MenuItem />}
              />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify" element={<VerifyOTP />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/orders" element={<Orders />} />

              {/* Protected routes */}
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/payment"
                element={
                  <PrivateRoute>
                    <Payment />
                  </PrivateRoute>
                }
              />

              {/* Catch-all route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </MobileView>
    </>
  );
};

export default App;
